<script>
    import moment from 'moment'
    import axios from 'axios'

    export default {
        props: ['errors', 'data', 'url', 'calculator', 'fund_id'],
        data() {
            return {
                price: null,
                dialogVisible: false,
                form: {
                    'amount': 0,
                    'date': null,
                    'maturity_date': null,
                    'rate': null
                }
            }
        },
        computed: {
            'tenor_days': function () {
                if((!this.form.maturity_date) || (!this.form.date)){
                    return null
                }

                let maturity = moment(this.form.maturity_date);
                let value = moment(this.form.date);

                return maturity.diff(value, 'days')
            },
            value: function (){
              return this.form.amount * this.price
            }
        },
        watch: {
          'form.date': function (){
              if(this.calculator !== 'daily_price'){
                return
              }
              let vm = this
              axios.get('/funds/'+this.fund_id+'/price?date='+this.form.date)
                .then(function (response){
                  vm.price = response.data.price
                }, function (){

                })

          }
        },
        created: function () {
            this.form.date = moment().format('YYYY-MM-DD')
        }

    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="success button"><i class="el-icon-plus"></i> Invest</button>

        <el-dialog
          title="Invest"
          :visible.sync="dialogVisible"
        >
          <span>
              <form method="POST" class="form wide cols2" :action="url">
                  <slot name="csrf"></slot>
                <div class="holder">
                    <label for="amount">
                      <span v-if="calculator !== 'daily_price'">Amount | </span>{{ form.amount | currency('') }}
                      <span v-if="calculator === 'daily_price'"> units @ {{ price | currency('' )}} = {{ value | currency('')}}</span>
                    </label>
                    <input type="number" step="0.00000001" name="amount" id="amount" v-model="form.amount" required="true">
                    <form-errors name="amount" :errors="errors"></form-errors>
                </div>

                <div class="holder">
                    <label for="date">Date</label>
                    <el-date-picker
                      v-model="form.date"
                      type="date"
                      placeholder="Pick a day"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      required="true"
                    >
                    </el-date-picker>
                    <input name="date" type="hidden" v-model="form.date"></input>
                    <form-errors name="date" :errors="errors"></form-errors>
                </div>

                  <template v-if="calculator === 'fixed_interest' ">
                      <div class="holder">
                          <label for="maturity_date">Maturity Date | Tenor: {{ tenor_days }} days </label>
                          <el-date-picker
                              v-model="form.maturity_date"
                              type="date"
                              placeholder="Pick a day"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              required="true"
                              id="maturity_date"
                            >
                            </el-date-picker>
                            <input name="maturity_date" type="hidden" v-model="form.maturity_date"></input>
                            <form-errors name="maturity_date" :errors="errors"></form-errors>
                      </div>

                      <div class="holder">
                          <input type="checkbox" name="on_call" id="on_call" value="True">
                          <label for="on_call">On Call</label>
                      </div>

                      <div class="holder">
                          <label for="rate">Interest Rate (%)</label>
                          <input name="rate" type="number" id="rate" v-model="form.rate" step="0.001" required="true">
                          <form-errors name="rate" :errors="errors"></form-errors>
                      </div>

                      <div class="holder">
                          <label for="interest_payment_duration">Interest Payment</label>
                          <select name="interest_payment_duration" id="interest_payment_duration">
                              <option selected value="">On Maturity</option>
                              <option value="1">Monthly</option>
                              <option value="3">Quarterly</option>
                              <option value="6">Every 6 Months</option>
                              <option value="12">Annually</option>
                          </select>
                          <form-errors name="interest_payment_duration" :errors="errors"></form-errors>
                      </div>
                      <div class="holder">
                        <label for="award_commission">Award Commission</label>
                        <select name="award_commission" id="award_commission">
                              <option selected value="1">Full</option>
                              <option value="0.5">Half</option>
                              <option value="0">None</option>
                        </select>
                        <form-errors name="award_commission" :errors="errors"></form-errors>
                      </div>
                  </template>

                  <div class="w-full">
                      <div class="button-group holder">
                      <button type="submit" class="success button">Save</button>
                      <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                  </div>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>