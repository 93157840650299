<script>
    import moment from 'moment'
    export default {
        props: ['bank_accounts', 'errors', 'data'],
        data(){
            return {
                'form': {
                    bank_account_id: null,
                    amount: null,
                    bank_ref_no: null,
                    entry_date:null,
                    value_date:null,
                    source: null,
                    narration: '',
                    cheque_number: null
                },
            }
        },
        methods: {
            optional: function (conditional, fallback) {
                return conditional ? conditional : fallback
            }
        },
        created: function () {
            this.form.source = this.optional(this.data.source,'deposit')
            this.form.value_date = this.data.value_date ? moment(this.data.value_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
            this.form.entry_date = this.data.entry_date ? moment(this.data.entry_date).format('YYYY-MM-DD') : null;
            this.form.narration = this.optional(this.data.narration, 'Client deposit');
            this.form.bank_account_id = this.optional(this.data.bank_account_id, this.bank_accounts[0].id);
            this.form.bank_ref_no = this.optional(this.data.bank_ref_no, null)
            this.form.cheque_number = this.optional(this.data.cheque_number, null)
            this.form.amount = this.optional(this.data.amount, null)
        }
    }
</script>
<template>
    <div>
        <form method="post" enctype="multipart/form-data" class="form wide cols2">
            <slot name="csrf"></slot>
            <div class="holder">
                <label>Bank Account*</label>
                <select name="bank_account_id" id="bank_account_id" required v-model="form.bank_account_id">
                    <option v-for="bank in bank_accounts" :value="bank.id">{{ bank.name }}</option>
                </select>
                <form-errors name="bank_account_id" :errors="errors"></form-errors>
            </div>

            <div class="holder">
                <label>Source*</label>
                <select name="source" id="source" required v-model="form.source">
                    <option value="deposit">Direct deposit/transfer</option>
                    <option value="mpesa">Mpesa</option>
                    <option value="cheque">Cheque</option>
                </select>
                <form-errors name="source" :errors="errors"></form-errors>
            </div>

            
            <div class="holder">
                <label for="bank_ref_no">Bank Reference <span class="extra">(Must be unique or empty)</span></label>

                <input type="text" name="bank_ref_no" id="bank_ref_no"  v-model="form.bank_ref_no">
                <form-errors name="bank_ref_no" :errors="errors"></form-errors>
            </div>

             <div class="holder">
                <label for="amount">Amount*</label>

                <input type="number" name="amount" id="amount" min="0" step="0.00000001" required="true"  v-model="form.amount">
                 <form-errors name="number" :errors="errors"></form-errors>
            </div>

            <div class="holder">
                <label for="narration">Narration*</label>

                <input type="text" name="narration" id="narration" required="true" v-model="form.narration">
                <form-errors name="narration" :errors="errors"></form-errors>
            </div>

            <div class="holder">
                <label for="value_date">Value Date*</label>

                <el-date-picker
                    id="value_date"
                  v-model="form.value_date"
                  type="date"
                  placeholder="Pick a day"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  name="value_date"
                >
                </el-date-picker>

                <form-errors name="value_date" :errors="errors"></form-errors>
            </div>

            <div class="holder" v-if="form.source === 'cheque'">
                <label for="entry_date">Entry Date*</label>

                <el-date-picker
                        id="entry_date"
                  v-model="form.entry_date"
                  type="date"
                  name="entry_date"
                  placeholder="Pick a day"
                    format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd">
                </el-date-picker>

                <form-errors name="entry_date" :errors="errors"></form-errors>
            </div>

            <div class="holder" v-if="form.source === 'cheque'">
                <label for="cheque_number">Cheque Number*</label>

                <input type="text" name="cheque_number" id="cheque_number" required="true" v-model="form.cheque_number">
                <form-errors name="cheque_number" :errors="errors"></form-errors>
            </div>

            <div class="holder" v-if="form.source === 'cheque'">
                <label for="cheque_number">Attach cheque</label>

                <input type="file" name="cheque_doc" id="cheque_doc">
                <form-errors name="cheque_doc" :errors="errors"></form-errors>
            </div>


            <div class="w-full">
                <button type="Submit" class="button ml-4">Save</button>
            </div>
        </form>
    </div>
</template>
<style scoped>
    label .extra{
        font-weight: 800;
        font-style: italic;
        font-size: 0.8rem;
    }
</style>