const JSEncrypt = require('./jsencrypt.min.js');
import { decode } from 'base-64';

export const encrypt = (plaintext, publicKey) => {
    const jsEncrypt = new JSEncrypt();

    jsEncrypt.setPublicKey(decode(publicKey));

    const encryptedText = jsEncrypt.encrypt(plaintext);

    return encryptedText;
};
