<script>
    import moment from 'moment'
    import axios from 'axios'

    export default {
        props: ['errors', 'data', 'url', 'investment_id', 'investment', 'balance', 'account_id', 'fund_id', 'calculator', 'bank_accounts'],
        data() {
            return {
                price: null,
                dialogVisible: false,
                form: {
                    'amount': 0,
                    'date': null
                },
                calculated_value: null,
                net_interest: null
            }
        },
        computed: {
            'premature': function(){
                if(!this.investment) return false

                return this.form.amount > parseFloat(this.net_interest) &&
                    moment(this.investment.maturity_date).isSameOrAfter(this.form.date)
            },
            value: function (){
              return this.form.amount * this.price
            }
        },
        methods: {

        },
        watch: {
            form: {
                deep: true,
                handler: function (before, after) {
                    let vm = this;
                    if(after.date && vm.account_id && vm.investment_id){
                        const url = '/api/accounts/'+vm.account_id+'/investments/'+vm.investment_id+'/?date='+vm.form.date;

                        axios.get(url).then(function ({data}) {
                            vm.calculated_value = data.value
                            vm.net_interest = data.net_interest_balance
                        }, function () {

                        });
                    }
                }
            },
            'form.date': function (){
              if(this.calculator !== 'daily_price'){
                return
              }
              let vm = this
              axios.get('/funds/'+this.fund_id+'/price?date='+this.form.date)
                .then(function (response){
                  vm.price = response.data.price
                }, function (){

                })

          }
        },
        created: function () {
            this.form.date = moment().format('YYYY-MM-DD')
        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="alert button"><i class="el-icon-minus"></i> Withdraw</button>

        <el-dialog
          title="Withdraw"
          :visible.sync="dialogVisible"
        >
          <span>
              <div class="flex flex-wrap px-3">
                  <template v-if="investment_id">
                      <div class="w-full md:w-1/2 py-2"><label>Amount Available Today:</label> {{ investment.total | currency('') }}</div> <br>
                      <div class="w-full md:w-1/2 py-2"><label>Amount Available @ Maturity:</label> {{ investment.total_maturity | currency('') }}</div> <br>
                      <div class="w-full md:w-1/2 py-2"><label>Amount Available @ {{ form.date }}:</label> {{ calculated_value | currency('') }}</div> <br>
                      <div class="w-full md:w-1/2 py-2"><label>Interest Available @ {{ form.date }}:</label> {{ net_interest | currency('') }}</div> <br>
                      <div class="w-full md:w-1/2 py-2"><label>Maturity Date:</label> {{ investment.maturity_date }}</div> <br>
                  </template>
                  <template v-else>
                      <div class="w-full md:w-1/2 py-2"><label>Account Balance:</label> {{ balance | currency('') }}</div> <br>
                  </template>
              </div>
              <form method="POST" class="form wide cols2" :action="url">
                  <slot name="csrf"></slot>
                <div class="holder">
                    <label for="amount">
                      <span v-if="calculator !== 'daily_price'">Amount | </span>{{ form.amount | currency('') }}
                      <span v-if="calculator === 'daily_price'"> units @ {{ price | currency('' )}} = {{ value | currency('')}}</span>
                    </label>
                    <input type="number" step="0.01" name="amount" id="amount" v-model="form.amount" required="true">
                    <form-errors name="amount" :errors="errors"></form-errors>
                </div>

                <div class="holder">
                    <label for="date">Date</label>
                    <el-date-picker
                      v-model="form.date"
                      type="date"
                      placeholder="Pick a day"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      required="true"
                      id="date"
                    >
                    </el-date-picker>
                    <input name="date" type="hidden" v-model="form.date"></input>
                    <form-errors name="date" :errors="errors"></form-errors>
                </div>
                <div class="holder">
                    <label for="client_account_id">Select Bank account</label>
                    <select name="client_account_id">
                        <option value="">Select Account</option>
                        <option v-for="account in bank_accounts" :value="account.id">{{ account.name }}</option>
                    </select>
                    <form-errors name="client_account_id" :errors="errors"></form-errors>
                </div>
                <div class="holder"></div>
                  <input type="hidden" name="investment_id" v-model="investment_id"></input>

                  <div class="callout alert w-full mx-4" v-if="premature">
                      Penalty will be charged if premature withdrawal is more than accrued interest.
                  </div>
                  <div class="button-group holder">
                      <button type="submit" class="success button">Save</button>
                      <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>