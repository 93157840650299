
<script>
    export default {
        props: ['url', 'item', 'text', 'title', 'btn_class', 'btn_text'],
        computed: {
            get_title: function () {
                return this.title ? this.title : 'Confirmation'
            },
            get_text: function () {
                return this.text ? this.text : 'Are you sure?'
            },
            get_btn_class: function () {
                return this.btn_class ? this.btn_class : 'small'
            },
            get_btn_text: function () {
                return this.btn_text ? this.btn_text : 'Yes, Proceed'
            }
        },
        methods: {
            open: function() {
                let vm = this;

                this.$alert(vm.get_text, vm.get_title, {
                  confirmButtonText: vm.get_btn_text,
                  callback: action => {
                      if(action === 'confirm') {
                        window.location.href = vm.url
                      }
                  }
                });
            }
        }
    }
</script>
<template>
    <span>
        <a :class="get_btn_class+' button'" @click="open()">
            <slot></slot>
        </a>
    </span>
</template>