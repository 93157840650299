<script>
    import axios from 'axios'
    import {encrypt} from './encrypt'

    export default {
        props: ['public_key'],
        data(){
            return {
                loading: false,
                token_sent: false,
                form: {
                    'username': null,
                    'password': null,
                    'token': null
                }
            }
        },
        methods: {
            encrypt_form: function () {
                const enc = encrypt(this.form.password, this.public_key)
                
                return {
                    ...this.form,
                    'encrypted_password': enc,
                    'password': enc ? '' : this.form.password
                }
            },
            submit: function () {
                let vm = this;
                const form = this.encrypt_form()

                axios.post('login-2fa/', form)
                .then(function ({data}) {
                    if(!data.success){
                        vm.$notify({
                            message: data.message,
                            type: 'error',
                            duration: 20000
                         });
                    }
                    else {
                        vm.token_sent = true;
                        vm.$notify({
                            message: 'Token has been sent to your email',
                            type: 'success',
                            duration: 20000
                        });
                    }
                }, function ({data}) {
                    vm.$notify({
                        message: 'Could not send token',
                        type: 'error',
                        duration: 20000
                    });
                }).finally(function () {
                    vm.loading = false
                });

                this.loading = true
            },
            login: function () {
                this.loading = true
                const form = this.encrypt_form()
                const url = window?.location?.href ?? '/login/'

                const htmlForm = document.getElementById('login_form')
                htmlForm.action = url

                if (form.encrypted_password)   {
                    document.getElementById('id_password_enc').value = form.encrypted_password
                    document.getElementById('id_password').value = ''
                }

                htmlForm.submit()
            }
        }
    }
</script>
<template>
    <form method="POST" id="login_form" class="w-full sm:w-3/4 md:w-1/2 ml-auto mr-auto">
        <slot></slot>

        <div id="div_id_username" class="holder">
            <label for="id_username" class="required">Username<span class="asterisk">*</span></label>
            <input type="text" name="username" autofocus="autofocus" autocapitalize="none" autocomplete="off" maxlength="150" required="required" id="id_username" class="textinput textInput" v-model="form.username">
        </div>

        <div id="div_id_password" class="holder">
            <label for="id_password" class="required">Password<span class="asterisk">*</span></label>
            <input type="password" name="password" autocomplete="off" required="required" id="id_password" class="textinput textInput" v-model="form.password">
            <input type="hidden" name="encrypted_password" id="id_password_enc" class="textinput textInput">
        </div>

        <div id="div_id_token" class="holder" v-if="token_sent">
            <label for="id_token" class="required">Token (from your email)<span class="asterisk">*</span></label>
            <input type="text" name="token" autocomplete="off" id="id_token" class="textinput textInput" v-model="form.token">
        </div>

        <div class="holder" v-if="!token_sent">
            <button @click.prevent="submit()" class="expanded rounded button bg-brand-main"><i v-if="loading" class="el-icon-loading"></i> <i v-else class="el-icon-message"></i> Request Token</button>
        </div>

        <div class="holder" v-if="token_sent">
            <button @click.prevent="login()" class="expanded rounded button bg-brand-main" type="submit"><i v-if="loading" class="el-icon-loading"></i><i v-else class="fas fa-sign-in-alt"></i> Login</button>
        </div>
    </form>
</template>