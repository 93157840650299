<script>
    import moment from "moment";

    export default {
        props: ['account_id', 'view_url', 'send_url', 'valuation_url', 'calculator'],
        data() {
            return {
                dialogVisible: false,
                start_date: null,
                combined: false,
                date: null
            }
        },
        created: function () {
             this.date = moment().format('YYYY-MM-DD')
        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="secondary button"><i class="el-icon-view"></i> Statement</button>

        <el-dialog
          title="Statement"
          :visible.sync="dialogVisible"
        >
          <span>
              <el-collapse accordion>
                  <el-collapse-item title="View Statement" name="1">
                        <form method="POST" class="form wide cols2" :action="view_url">
                              <slot name="csrf"></slot>
                            <input type="hidden" name="account_id" v-model="account_id"></input>

                            <div class="holder">
                                <label>Start Date</label>
                                <el-date-picker
                                  v-model="start_date"
                                  type="date"
                                  placeholder="Pick a day"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  required="true"
                                >
                                </el-date-picker>
                                <input name="start_date" type="hidden" v-model="start_date"></input>
                            </div>

                            <div class="holder">
                                <label>Date</label>
                                <el-date-picker
                                  v-model="date"
                                  type="date"
                                  placeholder="Pick a day"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  required="true"
                                >
                                </el-date-picker>
                                <input name="date" type="hidden" v-model="date"></input>
                            </div>

                            <div class="holder">
                              <el-checkbox v-model="combined" border>Combine with other accounts</el-checkbox>
                              <input class="hidden" name="combined" type="checkbox" v-model="combined">
                            </div>

                            <div class="w-full">
                                <div class="button-group holder">
                                <button type="submit" class="success button">View</button>
                            </div>
                            </div>
                        </form>
                  </el-collapse-item>
                  <el-collapse-item title="Send Statement" name="2">
                    <form method="POST" class="form wide cols2" :action="send_url">
                          <slot name="csrf"></slot>
                        <input type="hidden" name="account_id" v-model="account_id"></input>

                        <div class="holder">
                            <label>Start Date</label>
                            <el-date-picker
                              v-model="start_date"
                              type="date"
                              placeholder="Pick a day"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              required="true"
                            >
                            </el-date-picker>
                            <input name="start_date" type="hidden" v-model="start_date"></input>
                        </div>

                        <div class="holder">
                            <label>Date</label>
                            <el-date-picker
                              v-model="date"
                              type="date"
                              placeholder="Pick a day"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              required="true"
                            >
                            </el-date-picker>
                            <input name="date" type="hidden" v-model="date"></input>
                        </div>

                        <div class="holder">
                              <el-checkbox v-model="combined" border>Combine with other accounts</el-checkbox>
                              <input class="hidden" name="combined" type="checkbox" v-model="combined">
                        </div>

                        <div class="w-full">
                            <div class="button-group holder">
                            <button type="submit" class="success button">Send</button>
                        </div>
                        </div>
                    </form>
                  </el-collapse-item>
                  <el-collapse-item title="Download Valuation" name="3" v-if="calculator === 'daily_yield'">
                    <form method="POST" class="form wide cols2" :action="valuation_url">
                          <slot name="csrf"></slot>
                        <input type="hidden" name="account_id" v-model="account_id"></input>

                        <div class="holder">
                            <label>Start Date</label>
                            <el-date-picker
                              v-model="start_date"
                              type="date"
                              placeholder="Pick a day"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              required="true"
                            >
                            </el-date-picker>
                            <input name="start_date" type="hidden" v-model="start_date"></input>
                        </div>

                        <div class="holder">
                            <label>Date</label>
                            <el-date-picker
                              v-model="date"
                              type="date"
                              placeholder="Pick a day"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              required="true"
                            >
                            </el-date-picker>
                        <input name="date" type="hidden" v-model="date"></input>
                        </div>

                          <div class="w-full">
                              <div class="button-group holder">
                              <button type="submit" class="success button">Download (xlsx)</button>
                          </div>
                          </div>
                    </form>
                  </el-collapse-item>
              </el-collapse>

          </span>
        <span slot="footer" class="dialog-footer">
            <button class="secondary button" @click="dialogVisible = false">Cancel</button>
        </span>
        </el-dialog>
    </div>
</template>