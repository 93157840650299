<script>
    import axios from 'axios'

    export default {
        props: ['errors', 'data', 'url', 'calculator', 'investment', 'product'],
        data: function () {
            return {
                "dialogVisible": false,
                "form": {
                    account_id: null,
                    narration: '',
                },
                "accounts": []
            }
        },
        methods: {
            'search': function (query) {
                console.log("running")
                let vm = this
                axios.get('/api/accounts/', {params: {
                'search': query, 'limit': 100, 'ordering': 'number'
                // 'product_id':this.product
                }})
                .then(function ({data}) {
                    vm.accounts = data.results
                }, function () {

                })
            }
        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="button"><i class="el-icon-right"></i> Transfer</button>

        <el-dialog
          title="Transfer"
          :visible.sync="dialogVisible"
        >
          <span>
              <form method="POST" class="form wide cols2" :action="url">
                  <slot name="csrf"></slot>
                  <div class="holder">
                      <label for="client">Receiving Account</label>
                      <el-select v-model="form.account_id"
                                 placeholder="Please select account" filterable
                                 remote :remote-method="search"
                      >
                        <el-option v-for="account in accounts" :key="account.id"
                          :label="account.client.name + ' - A/C:' + account.number"
                          :value="account.id">
                        </el-option>
                      </el-select>
                      <input name="client" type="hidden" id="client" v-model="form.account_id">
                      <form-errors name="client" :errors="errors"></form-errors>
                  </div>
                  <div class="holder">
                      <label for="narration">Narration</label>
                      <input type="text" v-model="form.narration" id="narration" name="narration" placeholder="Enter narration">
                      <form-errors name="client" :errors="errors"></form-errors>
                  </div>
                  <input type="hidden" name="investment_id" v-model="investment.id">
                  <div class="w-full">
                      <div class="button-group holder">
                          <button type="submit" class="success button">Save</button>
                          <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                      </div>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>
