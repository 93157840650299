<script>
  export default {
    props: ['title', 'btn_class', 'btn_text', 'width', 'title', 'confirm'],
    data() {
      return {
        dialogVisible: false
      };
    },
    methods: {
      handleClose(done) {
        !this.confirm ? done() : this.$confirm('Are you sure you want to close this dialog?')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      }
    }
  };
</script>
<template>
    <span class="modal__container">
        <el-button type="text" @click="dialogVisible = true" class="modal__button" :class="btn_class ?? 'primary button'">{{ btn_text ?? "Open Modal" }}</el-button>

        <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :width="width ?? '30%'"
        :before-close="handleClose">
        <slot></slot>
        </el-dialog>
    </span>
</template>
<style scoped>
.modal__container {
    margin: 0;
    padding: 0;
}
.modal__button {
    margin-bottom: 0;
}
.button-group .button {
    height: 100%
}
</style>
